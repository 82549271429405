import React from 'react'

const SVGPattern = () => (
  <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
    <defs>
      <pattern id="svg-pattern-squares-3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
      </pattern>
    </defs>
    <rect width="404" height="784" fill="url(#svg-pattern-squares-3)" />
  </svg>
)

export default SVGPattern