import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { YellowButton } from '../../components/Global/Button'
import SEO from '../../components/Global/SEO'
import { ContentWrapper, InfoWithImageRight, SVGPattern, InfoWithImageLeft } from '../../components/Global/Content'
import SectionTitle from '../../components/Global/SectionTitle'
import SimpleCenteredTestimonial from '../../components/Global/Testimonial/SimpleCentered'

const WeddingsPage = ({ location }) => {
  const { seoImg, wedding, weddingbride, champagne, rebecca } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "professional-wedding-car-service.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      wedding: file(relativePath: { eq: "professional-wedding-car-service.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      weddingbride: file(relativePath: { eq: "wedding-bride.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 980, maxHeight: 1140, cropFocus: WEST) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      champagne: file(relativePath: { eq: "champagne-wedding.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 980, maxHeight: 1140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rebecca: file(relativePath: { eq: "rebecca-bailey.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxHeight: 40, maxWidth: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Professional Wedding Car Service | Wedding Day Luxury Chauffeured Vehicles"
        description="Our luxury Wedding Car hire service makes your wedding day a very special day, letting you travel in a carefree mode with our special event chauffeurs."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={wedding.childImageSharp.fluid}
        imageAlt="Professional Wedding Car Service"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Services
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Professional Wedding Car
          <br className="hidden md:inline" />
          <span className="text-yellow-300"> Service</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          We offer a wide range of vehicles for wedding car hire in Ireland and our professional chauffeurs ensure you reach your venue on time with precision and care.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>
        <SectionTitle
          title="Luxury Wedding Car Hire"
          text="Our luxury Wedding Car hire service makes your wedding day a very special day, letting you travel in a carefree mode with our special event chauffeurs."
        />
        <InfoWithImageRight
          image={weddingbride.childImageSharp.fluid}
          imageAlt="Wedding car hire with Chauffeur Me for bride."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Exceptional Wedding Chauffeurs
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            For those of you looking to hire transportation for your special day, Chauffeur Me has the best calibre of vehicles to match the occasion.
          </p>

          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7L20 7M20 7L16 3M20 7L16 11M16 17L4 17M4 17L8 21M4 17L8 13" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Memorable Journey</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    No matter how far your venue, we make every journey a memorable one with our professional wedding chauffeurs. The chauffeur will arrive at least 15 minutes before departure, to help ease any nerves.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Professional Chauffeur</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    You can expect a professional and experienced chauffeur driver on your wedding day. The chauffeur will be immaculately dressed in a smart suit and tie.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Luxury Vehicles</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Pick from our fleet of luxury vehicles suitable for wedding car hire, all of which are sure to grace the entrance of any venue with their elegant presence.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </InfoWithImageRight>
        <SVGPattern />
        <InfoWithImageLeft
          image={champagne.childImageSharp.fluid}
          imageAlt="Celebrate a wedding day with Chauffeur Me luxury cars."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Special Wedding Day
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Every single detail regarding the occasion is taken into consideration to make your wedding day special.
          </p>

          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Details Matter</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Factors like the dress and traveling to the venue are of utmost importantance, we can also decorate your wedding car on demand to match the occasion.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Champagne Toast</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    We also allow both of you to toast the occasion with Champagne or your favourite tipple and make sure you arrive safe and suitably elated.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </InfoWithImageLeft>
      </ContentWrapper>

      <SimpleCenteredTestimonial
        content="They did the wedding of my daughter Hannah and Shaun. They were excellent at communicating with us. They were very punctual and the driver was very polite and respectful."
        author="Rebecca Bailey"
        position="Wedding (14th February 2020)"
        image={rebecca.childImageSharp.fluid}
      />

    </Layout>
  )
}

export default WeddingsPage