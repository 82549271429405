import React from 'react'
import PropTypes from 'prop-types'

const ContentWrapper = ({ children, backgroundColor }) => (
  <div className={`py-16 overflow-hidden lg:py-24 ${backgroundColor}`}>
    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      {children}
    </div>
  </div>
)

export default ContentWrapper

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
}
ContentWrapper.defaultProps = {
  backgroundColor: 'bg-gray-50',
}