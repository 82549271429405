import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const InfoWithImageLeft = ({ image, imageAlt, children, noPadding }) => (
  <div className={`${noPadding ? '' : 'mt-12 sm:mt-16 lg:mt-24'} lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center`}>
    <div className="mb-10 lg:mb-0 lg:-mx-4 relative lg:mt-0 lg:col-start-1">
      <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
        <defs>
          <pattern id="svg-pattern-squares-4" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="784" height="404" fill="url(#svg-pattern-squares-4)" />
      </svg>
      <div className="relative mx-auto w-full rounded-md shadow-lg lg:max-w-md">
        <Img fluid={image} alt={imageAlt} className="relative mx-auto rounded-md" />
      </div>
    </div>
    <div className="lg:col-start-2">
      {children}
    </div>
  </div>
)

export default InfoWithImageLeft

InfoWithImageLeft.propTypes = {
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool
}

InfoWithImageLeft.defaultProps = {
  noPadding: false
}