import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const SimpleCenteredTestimonial = ({ content, author, image, position, backgroundColor }) => (
  <div className={`py-12 overflow-hidden md:py-20 lg:py-24 ${backgroundColor}`}>
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <svg className="absolute top-full left-full transform translate-x-1/3 -translate-y-1/4 lg:-translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
        <defs>
          <pattern id="ad119f34-7694-4c31-947f-5c9d249b21f3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
      </svg>

      <div className="relative">
        <blockquote className="mt-8">
          <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
            <p>
              &ldquo;{content}&rdquo;
            </p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              {image && <div className="md:flex-shrink-0">
                <Img fluid={image} alt={author} className="mx-auto h-10 w-10 rounded-full shadow-md" />
              </div>}
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-900">{author}</div>
                {position && <><svg className="hidden md:block mx-1 h-5 w-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-500">{position}</div></>}
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
)

export default SimpleCenteredTestimonial

SimpleCenteredTestimonial.propTypes = {
  content: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  image: PropTypes.object,
  position: PropTypes.string,
  backgroundColor: PropTypes.string,
}

SimpleCenteredTestimonial.defaultProps = {
  backgroundColor: 'bg-white'
}