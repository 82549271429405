import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const LeftWithImage = ({ image, imageAlt, children }) => (
  <div className="relative bg-white overflow-hidden">
    <div className="hidden lg:block lg:absolute lg:inset-0">
      <svg className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784">
        <defs>
          <pattern id="svg-pattern-squares-desktop" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
        <rect x="118" width="404" height="784" fill="url(#svg-pattern-squares-desktop)" />
      </svg>
    </div>
    <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
      <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            {children}
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <svg className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784">
              <defs>
                <pattern id="svg-pattern-squares-mobile" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
              <rect x="118" width="404" height="784" fill="url(#svg-pattern-squares-mobile)" />
            </svg>
            <div className="relative mx-auto w-full rounded-md shadow-lg lg:max-w-md">
              <Img className="w-full rounded-md" fluid={image} alt={imageAlt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

LeftWithImage.propTypes = {
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string,
  children: PropTypes.node.isRequired,
}

LeftWithImage.defaultProps = {
  imageAlt: ''
}

export default LeftWithImage